/* Responsive Stuff */
.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
  width: 100%;
}

/* COL LG - ANYTHING ABOVE 992px */
@media (min-width: 1026px) {
  .container {
    max-width: 1150px;
  }
  .blog-mobile-box {
    display: none;
  }
  body, html {
    overflow-x: hidden;
  }
}

@media (min-width: 767px) and (max-width: 1025px) {
  body, html {
    overflow-x: hidden;
  }
  .container {
    width: 100%;
  }
  .navbar-nav .nav-link {
    padding: 5px;
    line-height: 1;
  }
  .pad-right, .pad-left, .pad {
    padding: 0;
  }
  .container {
    padding: 0 !important;
  }
  .pad-sides-mobile {
    padding: 0 15px !important;
  }
  .mar-top-mobile {
    margin-top: 15px !important;
  }
  .header-text {
    margin-top: 15px;
  }
  .image-header {
    margin-top: 15px;
    padding: 15px;
  }
  /* HEADER */
  header .navbar {
    height: auto;
    padding: 15px;
  }
  small {
    font-size: 100% !important;
    font-weight: normal !important;
  }
  header.small .navbar {
    height: auto !important;
  }
  .logo {
    height: 50px !important;
    width: auto;
  }
  .float-lg-right {
    float: right;
  }
  header .nav-icon {
    font-size: 16px;
    line-height: 1;
  }
  .navbar-toggler {
    background-image: none !important;
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    transition: all 1s;
    border-radius: 0;
    float: right;
  }
  .navbar-toggler:hover {
    color: #000000 !important;
  }
  .navbar-toggleable-sm .navbar-nav .nav-item {
    text-align: center;
  }
  /* FOOTER */
  .footer-menu {
    display: block;
  }
  .footer-menu li {
    width: 100% !important;
  }
  .footer-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  .app-menu {
    display: block;
  }
  .app-menu li {
    width: 100% !important;
  }
  .app-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  /* CONTACT */
  .contact-page {
    text-align: center;
  }
  /* ABOUT */
  .about-page {
    text-align: center;
  }
  /* HOME PAGE */
  .slick-home-slider .slide-right {
    display: none !important;
  }
  .slick-home-slider .slide-left {
    display: none !important;
  }
  .index-box {
    margin: 15px 0 0 0;
  }
  .blog-page {
    margin-top: 15px;
  }
  .article-gallery .slick-arrow {
    display: none !important;
  }
  .article-gallery {
    margin-top: 15px;
  }
  .index-link {
    display: none;
  }
  .index-link-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px 0;
    font-weight: bold;
  }
  .blog-card-box {
    display: none;
  }
  .mobile-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
  }
  .blog-mobile-box {
    margin-bottom: 15px;
    height: 250px;
    overflow: hidden;
    position: relative;
    margin-right: 15px;
  }
  .blog-mobile-box img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: 100%;
  }
  .navbar-nav .nav-link {
    padding: 13px 5px;
  }
  .col-md-4 {
    width: 50%;
  }
}

/* COL SM - FROM 576px UP TO 767px */
@media (min-width: 576px) and (max-width: 737px) {
  body, html {
    overflow-x: hidden;
  }
  .container {
    width: 100%;
  }
  .navbar-nav .nav-link {
    padding: 5px;
    line-height: 1;
  }
  .pad-right, .pad-left, .pad {
    padding: 0;
  }
  .container {
    padding: 0 !important;
  }
  .pad-sides-mobile {
    padding: 0 15px !important;
  }
  .mar-top-mobile {
    margin-top: 15px !important;
  }
  .header-text {
    margin-top: 15px;
  }
  .image-header {
    margin-top: 15px;
    padding: 15px;
  }
  /* HEADER */
  header .navbar {
    height: auto;
    padding: 15px;
  }
  small {
    font-size: 100% !important;
    font-weight: normal !important;
  }
  header.small .navbar {
    height: auto !important;
  }
  .mobile-logo {
    height: 30px !important;
    width: auto;
  }
  .navbar-toggler {
    background-image: none !important;
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    transition: all 1s;
    border-radius: 0;
    float: right;
  }
  .navbar-toggler:hover {
    color: #000000 !important;
  }
  .navbar-toggleable-sm .navbar-nav .nav-item {
    text-align: center;
  }
  /* FOOTER */
  .footer-menu {
    display: block;
  }
  .footer-menu li {
    width: 100% !important;
  }
  .footer-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  .app-menu {
    display: block;
  }
  .app-menu li {
    width: 100% !important;
  }
  .app-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  /* CONTACT */
  .contact-page {
    text-align: center;
  }
  /* ABOUT */
  .about-page {
    text-align: center;
  }
  /* HOME PAGE */
  .slick-home-slider .slide-right {
    display: none !important;
  }
  .slick-home-slider .slide-left {
    display: none !important;
  }
  .index-box {
    margin: 15px 0 0 0;
  }
  .blog-page {
    margin-top: 15px;
  }
  .article-gallery .slick-arrow {
    display: none !important;
  }
  .article-gallery {
    margin-top: 15px;
  }
  .index-link {
    display: none;
  }
  .index-link-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px 0;
    font-weight: bold;
  }
  .blog-card-box {
    display: none;
  }
  .mobile-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
  }
  .blog-mobile-box {
    margin-bottom: 15px;
    height: 250px;
    overflow: hidden;
    position: relative;
    margin-right: 15px;
  }
  .blog-mobile-box img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: 100%;
  }
}

@media (max-width: 670px) {
  .wow {
    opacity: 1;
    visibility: visible !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  body, html {
    overflow-x: hidden;
  }
  .pad-right, .pad-left, .pad {
    padding: 0;
  }
  .mar-right, .mar-left {
    margin: 0;
  }
  .pad-sides-mobile {
    padding: 0 15px !important;
  }
  .mar-top-mobile {
    margin-top: 15px !important;
  }
  .header-text {
    margin-top: 15px;
    padding-top: 15px;
  }
  .image-header {
    margin-top: 15px;
    padding: 15px;
  }
  .text-xs-center {
    text-align: center;
  }
  /* HEADER */
  header .navbar {
    height: auto;
  }
  small {
    font-size: 100% !important;
    font-weight: normal !important;
  }
  header.small .navbar {
    height: auto !important;
  }
  .mobile-logo {
    height: 30px !important;
    width: auto;
  }
  .navbar-toggler {
    background-image: none !important;
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    transition: all 1s;
    border-radius: 0;
    float: right;
  }
  .navbar-toggler:hover {
    color: #000000 !important;
  }
  .navbar-toggleable-sm .navbar-nav .nav-item {
    text-align: center;
  }
  .navbar-nav .nav-link {
    padding: 5px;
    line-height: 1;
  }
  /* FOOTER */
  .footer-menu {
    display: block;
  }
  .footer-menu li {
    width: 100% !important;
  }
  .footer-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  .app-menu {
    display: block;
  }
  .app-menu li {
    width: 100% !important;
  }
  .app-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  /* CONTACT */
  .contact-page {
    text-align: center;
  }
  /* EBOOK */
  .ebook-image-wrap {
    margin-top: 15px;
  }
  /* ABOUT */
  .about-page {
    text-align: center;
  }
  /* HOME PAGE */
  .slick-home-slider .slide-right {
    display: none !important;
  }
  .slick-home-slider .slide-left {
    display: none !important;
  }
  .index-box {
    margin: 15px 0 0 0;
  }
  .blog-page {
    margin-top: 15px;
  }
  .article-gallery .slick-arrow {
    display: none !important;
  }
  .article-gallery {
    margin-top: 15px;
  }
  .index-link {
    display: none;
  }
  .index-link-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px 0;
    font-weight: bold;
  }
  .article-title {
    font-size: 1.5rem;
  }
  .blog-card-box {
    display: none;
  }
  .mobile-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
  }
  .blog-mobile-box {
    margin-bottom: 15px;
    height: 250px;
    overflow: hidden;
    position: relative;
  }
  .blog-mobile-box img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: 100%;
  }
  #home-horses .bxslider {
    position: relative;
    left: 2%;
  }
  .container {
    padding: 15px;
  }
  .footer-one .newsletter-wrap {
    width: auto;
  }
  ul.side-menu {
    margin-top: 15px;
  }
  .menu-wrap .container {
    padding: 0px;
  }
}

/* COL XS - ANYTHING UP TO 575px */
@media (max-width: 567px) {
  .wow {
    opacity: 1;
    visibility: visible !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
  }
  body, html {
    overflow-x: hidden;
  }
  .pad-right, .pad-left, .pad {
    padding: 0;
  }
  .mar-right, .mar-left {
    margin: 0;
  }
  .pad-sides-mobile {
    padding: 0 15px !important;
  }
  .mar-top-mobile {
    margin-top: 15px !important;
  }
  .header-text {
    margin-top: 15px;
    padding-top: 15px;
  }
  .image-header {
    margin-top: 15px;
    padding: 15px;
  }
  .text-xs-center {
    text-align: center;
  }
  /* HEADER */
  header .navbar {
    height: auto;
  }
  small {
    font-size: 100% !important;
    font-weight: normal !important;
  }
  header.small .navbar {
    height: auto !important;
  }
  .mobile-logo {
    height: 30px !important;
    width: auto;
  }
  .navbar-toggler {
    background-image: none !important;
    color: #000 !important;
    background-color: transparent !important;
    border-color: transparent !important;
    transition: all 1s;
    border-radius: 0;
    float: right;
  }
  .navbar-toggler:hover {
    color: #000000 !important;
  }
  .navbar-toggleable-sm .navbar-nav .nav-item {
    text-align: center;
  }
  .navbar-nav .nav-link {
    padding: 5px;
    line-height: 1;
  }
  /* FOOTER */
  .footer-menu {
    display: block;
  }
  .footer-menu li {
    width: 100% !important;
  }
  .footer-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  .app-menu {
    display: block;
  }
  .app-menu li {
    width: 100% !important;
  }
  .app-menu li a {
    margin: 0 !important;
    float: none !important;
  }
  /* CONTACT */
  .contact-page {
    text-align: center;
  }
  /* EBOOK */
  .ebook-image-wrap {
    margin-top: 15px;
  }
  /* ABOUT */
  .about-page {
    text-align: center;
  }
  /* HOME PAGE */
  .slick-home-slider .slide-right {
    display: none !important;
  }
  .slick-home-slider .slide-left {
    display: none !important;
  }
  .index-box {
    margin: 15px 0 0 0;
  }
  .blog-page {
    margin-top: 15px;
  }
  .article-gallery .slick-arrow {
    display: none !important;
  }
  .article-gallery {
    margin-top: 15px;
  }
  .index-link {
    display: none;
  }
  .index-link-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 15px 0;
    font-weight: bold;
  }
  .article-title {
    font-size: 1.5rem;
  }
  .blog-card-box {
    display: none;
  }
  .mobile-overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    padding: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
  }
  .blog-mobile-box {
    margin-bottom: 15px;
    overflow: hidden;
  }
  .blog-mobile-box img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    width: 100%;
  }
  #home-horses .bxslider {
    position: relative;
    left: 10%;
  }
  .container {
    padding: 15px;
  }
  .footer-one .newsletter-wrap {
    width: auto;
  }
  ul.side-menu {
    margin-top: 15px;
  }
  .menu-wrap .container {
    padding: 0px;
  }
}
